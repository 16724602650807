import { useEffect } from "react";
import TopPageHeader from "./TopPageHeader";
import AboutUs from "./AboutUs";

const AboutPage = () => {
  useEffect(() => {
    document.title = "About Us | DRIVEZILLA - Experience The Difference";
  }, []);
  return (
    <>
      <TopPageHeader title={"About Us"} link={"/about-us"} />
      <AboutUs />
    </>
  );
};

export default AboutPage;
