import { useState } from "react";
import axios from "axios";

import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";

import slider1 from "../img/slider-1.jpg";
import slider2 from "../img/slider-2.jpeg";
import "./Home.css";
import WhyChooseUs from "./WhyChooseUs";
import AboutUs from "./AboutUs";
import { CITY_LIST, CAB_TYPES } from "../Constants";

const Home = () => {
  const [outstationType, setOutstationType] = useState(null);
  const [airportLocation, setAirportLocation] = useState("Location");
  const [airportValidated, setAirportValidated] = useState(false);
  const [localValidated, setLocalValidated] = useState(false);
  const [outstationValidated, setOutstationValidated] = useState(false);
  const [dedicatedValidated, setDedicatedValidated] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState({});
  const [enqFormData, updateEnqFormData] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [formError, setFormError] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const today = new Date(),
    todayDate =
      today.getFullYear() +
      "-" +
      ("0" + (today.getMonth() + 1)).slice(-2) +
      "-" +
      (today.getDate() + 1);

  const handleOutstationChange = (event) => {
    setOutstationType(event.target.value);
  };

  const handleAirportDutyType = (event) => {
    if (event.target.value === "AirportDrop") {
      setAirportLocation("Pickup Location");
    } else if (event.target.value === "CityDrop") {
      setAirportLocation("Drop Location");
    }
  };

  const handleAirportSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setFormData({});
      setAirportValidated(true);
    } else {
      setModalShow(true);
      setFormData({
        enquiry_type: form.elements.enquiry_type.value,
        city_name: form.elements.city_name.value,
        duty_type: form.elements.duty_type.value,
        airport_name: form.elements.airport_name.value,
        location_name: form.elements.location_name.value,
        car_type: form.elements.car_type.value,
        pickup_date: form.elements.pickup_date.value,
        pickup_time: form.elements.pickup_time.value,
      });
      setAirportValidated(true);
    }
  };
  const handleLocalSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setLocalValidated(true);
      setFormData({});
    } else {
      setLocalValidated(true);
      setModalShow(true);
      setFormData({
        enquiry_type: form.elements.enquiry_type.value,
        city_name: form.elements.city_name.value,
        local_package: form.elements.local_package.value,
        pickup_date: form.elements.pickup_date.value,
        pickup_time: form.elements.pickup_time.value,
        pickup_location: form.elements.pickup_location.value,
        car_type: form.elements.car_type.value,
      });
    }
  };
  const handleOutstationSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setOutstationValidated(true);
      setFormData({});
    } else {
      setOutstationValidated(true);
      setModalShow(true);
      setFormData({
        enquiry_type: form.elements?.enquiry_type?.value,
        outstation_type: form.elements?.outstation_type?.value,
        city_name: form.elements?.city_name?.value,
        pickup_location: form.elements?.pickup_location?.value,
        destination: form.elements?.destination?.value,
        pickup_date: form.elements?.pickup_date?.value,
        pickup_time: form.elements?.pickup_time?.value,
        drop_date: form.elements?.drop_date?.value,
        drop_time: form.elements?.drop_time?.value,
        car_type: form.elements?.car_type?.value,
      });
    }
  };
  const handleDedicatedSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
      setDedicatedValidated(true);
      setFormData({});
    } else {
      setDedicatedValidated(true);
      setModalShow(true);
      setFormData({
        enquiry_type: form.elements?.enquiry_type?.value,
        city_name: form.elements?.city_name?.value,
        pickup_date: form.elements?.pickup_date?.value,
        pickup_time: form.elements?.pickup_time?.value,
        drop_date: form.elements?.drop_date?.value,
        drop_time: form.elements?.drop_time?.value,
        car_type: form.elements?.car_type?.value,
      });
    }
  };

  // useEffect(() => {
  //   try {
  //     const response = axios.get("http://localhost:8080/api/get-token");
  //     localStorage.setItem("token", response.data.token);
  //     return response.data.token;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  const handleEnquiryForm = (e) => {
    e.preventDefault();
    const enqFormDataCheck = { ...enqFormData };
    if (
      enqFormDataCheck?.enq_name &&
      enqFormDataCheck?.enq_name.length > 3 &&
      enqFormDataCheck?.enq_number &&
      enqFormDataCheck?.enq_number.length === 10 &&
      enqFormDataCheck?.enq_email &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(enqFormDataCheck?.enq_email)
    ) {
      setDisableSubmit(true);
      setFormError(false);
      axios
        .post("https://www.bookingmaster.online/index.php/api/enquiry", {
          ...enqFormData,
          ...formData,
        })
        .then((response) => {
          setDisableSubmit(false);
          console.log();
          alert(`Booking Enquiry Submitted Successfully : ${response.data.data.bookingRef}`);
          window.location.href = "";
        })
        .catch((error) => {
          setDisableSubmit(false);
          alert("Error submitting form:", error);
          window.location.href = "";
        });
    } else {
      setFormError(true);
    }
  };

  const handleEnqChange = (e) => {
    updateEnqFormData({
      ...enqFormData,
      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };

  return (
    <>
      <Modal show={modalShow} onHide={handleModalClose} id="enquiry-model">
        <Modal.Header closeButton>Booking Enquiry Form</Modal.Header>
        <Modal.Body>
          <form noValidate method="post">
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                name="enq_name"
                onChange={handleEnqChange}
                id="name"
                placeholder="Enter your name"
                minLength="3"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="number"
                min={111111111}
                max={9999999999}
                name="enq_number"
                onChange={handleEnqChange}
                placeholder="Mobile Number"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Email"
                onChange={handleEnqChange}
                name="enq_email"
                required
              />
            </Form.Group>
            <Form.Group className="mb-3 text-right">
              <Button
                variant="primary"
                className="search-btn"
                type="button"
                disabled={disableSubmit}
                onClick={handleEnquiryForm}
              >
                Submit
              </Button>
            </Form.Group>
          </form>
          {formError && (
            <Alert key="danger" variant="danger">
              Please fill the form to Submit.
            </Alert>
          )}
        </Modal.Body>
      </Modal>

      <Carousel id="home-slider">
        <Carousel.Item>
          <img className="d-block w-100" src={slider1} alt="First slide" />
          <div className="carousel-overlay"></div>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={slider2} alt="Second slide" />
          <div className="carousel-overlay"></div>
        </Carousel.Item>
      </Carousel>
      {/* <div className="shade"></div> */}
      <Card id="search-form">
        <Tabs defaultActiveKey="AT" id="justify-tab-example" className="mb-2" fill>
          <Tab eventKey="AT" title="AIRPORT TRANSFER">
            <Form
              action=""
              method="post"
              noValidate
              validated={airportValidated}
              onSubmit={handleAirportSubmit}
            >
              <Form.Control type="hidden" required name="enquiry_type" value="AirportTransfer" />
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="city_name">
                      <option selected disabled value="">
                        Select City
                      </option>
                      {CITY_LIST.map((city, index) => (
                        <option key={index}>{city}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Check
                    inline
                    label="Airport Drop"
                    name="duty_type"
                    type="radio"
                    id="airport_drop"
                    onChange={handleAirportDutyType}
                    value="AirportDrop"
                    required
                  />
                  <Form.Check
                    inline
                    label="City Drop"
                    name="duty_type"
                    type="radio"
                    id="city_drop"
                    onChange={handleAirportDutyType}
                    value="CityDrop"
                    required
                  />
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="airport_name">
                      <option selected disabled value="">
                        Select Airport
                      </option>
                      <option>Terminal 1</option>
                      <option>Terminal 2</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder={airportLocation}
                      minlength="3"
                      required
                      name="location_name"
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="car_type">
                      <option selected disabled value="">
                        Car Type
                      </option>
                      {Object.keys(CAB_TYPES).map((cabType, groupIndex) => (
                        <optgroup label={cabType} key={groupIndex}>
                          {CAB_TYPES[cabType].map((cab, index) => (
                            <option key={index}>{cab}</option>
                          ))}
                        </optgroup>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>

                <Col sm={7}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="date"
                      name="pickup_date"
                      min={todayDate}
                      placeholder="Pickup Date"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="time"
                      name="pickup_time"
                      placeholder="Pickup Time"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="search-btn"
                    type="submit"
                    disabled={disableSubmit}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tab>
          <Tab eventKey="LO" title="LOCAL">
            <Form method="post" noValidate validated={localValidated} onSubmit={handleLocalSubmit}>
              <Form.Control type="hidden" required name="enquiry_type" value="LocalPackage" />
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="city_name">
                      <option selected disabled value="">
                        Select City
                      </option>
                      {CITY_LIST.map((city, index) => (
                        <option key={index}>{city}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="local_package">
                      <option selected disabled value="">
                        Select Duration
                      </option>
                      <option value="2|20">2 Hr 20 KM</option>
                      <option value="4|40">4 Hr 40 KM</option>
                      <option value="6|60">6 Hr 60 KM</option>
                      <option value="8|80">8 Hr 80 KM</option>
                      <option value="10|100">10 Hr 100 KM</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={7}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="date"
                      name="pickup_date"
                      min={todayDate}
                      placeholder="Pickup Date"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="time"
                      name="pickup_time"
                      placeholder="Pickup Time"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Pickup Location"
                      name="pickup_location"
                      minlength="3"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="car_type">
                      <option selected disabled value="">
                        Car Type
                      </option>
                      {Object.keys(CAB_TYPES).map((cabType, groupIndex) => (
                        <optgroup label={cabType} key={groupIndex}>
                          {CAB_TYPES[cabType].map((cab, index) => (
                            <option key={index}>{cab}</option>
                          ))}
                        </optgroup>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="search-btn"
                    type="submit"
                    disabled={disableSubmit}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tab>
          <Tab eventKey="OU" title="OUTSTATION">
            <Form
              method="post"
              noValidate
              validated={outstationValidated}
              onSubmit={handleOutstationSubmit}
            >
              <Form.Control type="hidden" required name="enquiry_type" value="Outstation" />
              <Row>
                <Col sm={12}>
                  <Form.Check
                    inline
                    label="Oneway"
                    name="outstation_type"
                    type="radio"
                    id="oneway"
                    value="oneWay"
                    checked={outstationType === "oneWay"}
                    onChange={handleOutstationChange}
                    required
                  />
                  <Form.Check
                    inline
                    label="Round Trip"
                    name="outstation_type"
                    type="radio"
                    id="roundtrip"
                    value="roundTrip"
                    checked={outstationType === "roundTrip"}
                    onChange={handleOutstationChange}
                    required
                  />
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="city_name">
                      <option selected disabled value="">
                        Select City
                      </option>
                      {CITY_LIST.map((city, index) => (
                        <option key={index}>{city}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="text"
                      placeholder="Pickup Location"
                      name="pickup_location"
                      minlength="3"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="text"
                      name="destination"
                      placeholder="Destination"
                      minlength="3"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col sm={7}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="date"
                      name="pickup_date"
                      min={todayDate}
                      placeholder="Pickup Date"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="time"
                      name="pickup_time"
                      placeholder="Pickup Time"
                      required
                    />
                  </Form.Group>
                </Col>

                {outstationType === "roundTrip" && (
                  <>
                    <Col sm={7}>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="date"
                          min={todayDate}
                          placeholder="Drop Date"
                          name="drop_date"
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={5}>
                      <Form.Group className="mb-2">
                        <Form.Control
                          type="time"
                          name="drop_time"
                          placeholder="Drop Time"
                          required
                        />
                      </Form.Group>
                    </Col>
                  </>
                )}
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="car_type">
                      <option selected disabled value="">
                        Car Type
                      </option>
                      {Object.keys(CAB_TYPES).map((cabType, groupIndex) => (
                        <optgroup label={cabType} key={groupIndex}>
                          {CAB_TYPES[cabType].map((cab, index) => (
                            <option key={index}>{cab}</option>
                          ))}
                        </optgroup>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="search-btn"
                    type="submit"
                    disabled={disableSubmit}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tab>

          <Tab eventKey="DC" title="DEDICATED CARS">
            <Form
              method="post"
              noValidate
              validated={dedicatedValidated}
              onSubmit={handleDedicatedSubmit}
            >
              <Form.Control type="hidden" required name="enquiry_type" value="DedicatedCabs" />
              <Row>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="city_name">
                      <option selected disabled value="">
                        Select City
                      </option>
                      {CITY_LIST.map((city, index) => (
                        <option key={index}>{city}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={7}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="date"
                      name="pickup_date"
                      min={todayDate}
                      placeholder="Start Date"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="time"
                      name="pickup_time"
                      placeholder="Start Time"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={7}>
                  <Form.Group className="mb-2">
                    <Form.Control
                      type="date"
                      name="drop_date"
                      min={todayDate}
                      placeholder="End Date"
                      required
                    />
                  </Form.Group>
                </Col>
                <Col sm={5}>
                  <Form.Group className="mb-2">
                    <Form.Control type="time" name="drop_time" placeholder="End Time" required />
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Form.Group className="mb-2">
                    <Form.Select required name="car_type">
                      <option selected disabled value="">
                        Car Type
                      </option>
                      {Object.keys(CAB_TYPES).map((cabType, groupIndex) => (
                        <optgroup label={cabType} key={groupIndex}>
                          {CAB_TYPES[cabType].map((cab, index) => (
                            <option key={index}>{cab}</option>
                          ))}
                        </optgroup>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className="search-btn"
                    type="submit"
                    disabled={disableSubmit}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tab>
        </Tabs>
      </Card>
      <AboutUs />
      <WhyChooseUs />
    </>
  );
};

export default Home;
