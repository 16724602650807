import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faMapLocation } from "@fortawesome/free-solid-svg-icons";
import TopPageHeader from "./TopPageHeader";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    document.title = "Contact Us | DRIVEZILLA - Experience The Difference";
  }, []);
  return (
    <>
      <TopPageHeader title={"Contact Us"} link={"/contact-us"} />
      <Container fluid>
        <Row>
          <Col sm={6}>
            <div className="left_panel">
              <h2 align="center">Contact Us</h2>
              <p>
                <FontAwesomeIcon icon={faEnvelope} color="gold" />{" "}
                <a href="mailto:info@drivezilla.in">info@drivezilla.in</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faPhone} color="gold" />{" "}
                <a href="tel:+918714841243">+91 871 484 1243</a>
              </p>
              <p>
                <FontAwesomeIcon icon={faMapLocation} color="gold" /> 70, 2nd cross, Udayanagar,
                Bangalore 560016
              </p>
              <Form method="post">
                <Row>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control type="text" placeholder="Name" />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control type="number" placeholder="Mobile" />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control type="email" placeholder="Email" />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Form.Group className="mb-3">
                      <Form.Control as="textarea" placeholder="Leave a comment here" />
                    </Form.Group>
                  </Col>
                  <Col sm={12}>
                    <Button variant="primary" className="submit-btn" type="submit">
                      Send
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
          <Col sm={6}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.597784626828!2d77.6723918!3d12.997557999999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae113eec7e77cd%3A0x4eb1e8addb85e2b9!2s70%2C%202nd%20Cross%20Rd%2C%20Bangalore%20Corporation%20Industrial%20Estate%2C%20Mahadevapura%2C%20Bengaluru%2C%20Karnataka%20560016!5e0!3m2!1sen!2sin!4v1676960399978!5m2!1sen!2sin"
              id="google-map-div"
              allowFullScreen=""
              loading="lazy"
              title="Drivezilla Location"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
