import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faPlane,
  faHandshake,
  faCalendarCheck,
  faUsers,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import logo from "../img/logo.png";
import "./Aboutus.css";

const AboutUs = () => {
  return (
    <Container id="about-us">
      <Row>
        <Col lg={12} className="text-center">
          <img src={logo} className="d-inline-block align-top about-logo" alt="DRIVEZILLA" />
          <p className="text-justify">
            Drivezilla was founded by a team of experienced entrepreneurs who saw a need for a
            reliable and affordable online car rental service in India. We have a combined
            experience of over 30 years in the transportation and customer service industries. We
            have a passion for providing excellent service and are dedicated to making online car
            rental accessible to everyone. Our vision is to build a company that stands out from the
            crowd by providing the best rates and the newest fleet on the market, equipped with
            cutting-edge technology to meet the diverse needs of our customers.
          </p>
          <p className="text-justify">
            We provide integrated people mobility solutions with the objective of providing
            best-in-class quality of service with an unmatched network spanning over 200 cities in
            India. A large fleet of on-road and resilient technology platforms is used to carry out
            the operations.The objective behind the venture is to connect each and every city in
            India with the latest trends and technologies.
          </p>
          <p className="text-justify">
            Our team is dedicated to providing excellent customer service and ensuring that each
            rental experience is a pleasant one. We strive to offer the best rates in the industry
            and are constantly updating our fleet to keep up with the latest trends and
            technologies.
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon={faBriefcase} size="5x" color="gold" />
            </div>
            <Card.Title className="feature-title">Business Trips</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon={faPlane} size="5x" color="gold" />
            </div>
            <Card.Title className="feature-title">Airport Transfers</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon={faHandshake} size="5x" color="gold" />
            </div>
            <Card.Title className="feature-title">Office Commutes</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon={faCalendarCheck} size="5x" color="gold" />
            </div>
            <Card.Title className="feature-title">Events</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon={faUsers} size="5x" color="gold" />
            </div>
            <Card.Title className="feature-title">Conferences</Card.Title>
          </Card>
        </Col>
        <Col>
          <Card className="feature-card">
            <div className="feature-icon">
              <FontAwesomeIcon icon={faPlus} size="5x" color="gold" />
            </div>
            <Card.Title className="feature-title">& More</Card.Title>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutUs;
