export const CITY_LIST = ["Bangalore"];
export const CAB_TYPES = {
  "Standard Series": ["Maruti Dzire", "Hyundai Accent", "Toyota Etios", "Honda Amaze"],
  "Executive Series": [
    "Honda City",
    "Maruti Ciaz",
    "Nissan Sunny",
    "Hyundai Verna",
    "Toyota Innova",
  ],
  "Superior Series": ["Toyota Altis", "Toyota Crysta", "Toyota Camry"],
  "Premium SUV": ["Audi A4", "Toyota Fortuner", "Mercedes ML 250", "Audi Q3"],
  "Premium Series": ["Mercedes E Class", "BMW 5 Series", "Audi A4"],
  "Premium Van": [
    "Mercedes Viano /V class",
    "Toyota Ventury",
    "Toyota Commuter",
    "Toyota Coaster",
    "Mercedes Sprinter",
  ],
  "Super Premium Series": ["Mercedes S Class", "BMW 7 Series", "Audi 8"],
};
